import React from 'react';
import TargetDetail from '../../components/TargetDetail';
import { InfraGrid } from '../../data/infra';
import { ConnectGrid } from '../../data/connect';
import { SecurityGrid } from '../../data/security';

const InfraDetail = () => <TargetDetail target="infra" TargetGrid={InfraGrid} />;
const ConnectDetail = () => <TargetDetail target="connect" TargetGrid={ConnectGrid} />;
const SecurityDetail = () => <TargetDetail target="security" TargetGrid={SecurityGrid} />;

export { InfraDetail, ConnectDetail, SecurityDetail };
