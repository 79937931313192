import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import OrderPage from '../../components/Order';

const GATEWAY_HOST = process.env.REACT_APP_ENV === 'prod'
  ? 'https://gateway.imesh.cloudtuple.com'
  : 'http://127.0.0.1:8000';
const PROTO_URL = `${GATEWAY_HOST}/proto`;

export const InfraInstall = () => (
  <OrderPage
    gatewayUrl={`${GATEWAY_HOST}/infra`}
    protoUrl={PROTO_URL}
    target="infra"
    action="install"
    icon={<AddIcon />}
  />
);

export const ConnectInstall = () => (
  <OrderPage
    gatewayUrl={`${GATEWAY_HOST}/connect`}
    protoUrl={PROTO_URL}
    target="connect"
    action="install"
    icon={<AddIcon />}
  />
);

export const SecurityInstall = () => (
  <OrderPage
    gatewayUrl={`${GATEWAY_HOST}/security`}
    protoUrl={PROTO_URL}
    target="security"
    action="install"
    icon={<AddIcon />}
  />
);
