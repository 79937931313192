import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, TextField, Typography, FormControl, InputLabel, Select, MenuItem, Tooltip, Checkbox, FormControlLabel } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import { PiCaretRightBold } from 'react-icons/pi';
import { makeStyles } from '@material-ui/core/styles';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { BarLoader } from 'react-spinners';
import LoadingOrder from './LoadingOrder';
import { ButtonAction } from './Buttons';
import { getBgColor, getIcon } from './Format';
import { Header } from '.';

const HEADER = process.env.REACT_APP_HEADER;
const headers = {
  accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Cloudtuple': HEADER,
};

const useStyles = makeStyles({
  root: {
    marginTop: '0px',
    marginLeft: '0px',
    marginRight: '40px',
    marginBottom: '20px',
    borderRadius: '10px',
    padding: '100px',
    maxWidth: '240px',
  },
  formControl: {
    minWidth: '200px',
    padding: '0px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '8px',
      },
      '& input': {
        padding: '8px', // Adjust the padding to reduce the height
        fontSize: '14px',
      },
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 10px) scale(1)', // Adjust the label's position
      fontSize: '14px',
      backgroundColor: 'white',
      padding: '0 4px',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)', // Adjust the label's position when shrunk
    },
  },
  loadingText: {
    marginTop: '20px',
    marginLeft: '0px',
    marginRight: '40px',
    marginBottom: '20px',
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '8px',
      },
      '& input': {
        padding: '8px', // Adjust the padding to reduce the height
        fontSize: '14px',
      },
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 10px) scale(1)', // Adjust the label's position
      fontSize: '14px',
      backgroundColor: 'white',
      padding: '0 4px',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)', // Adjust the label's position when shrunk
    },
  },
  fieldContainer: {
    marginBottom: '12px', // Adjust this value for larger space between fields
  },
  configHeader: {
    fontWeight: 'bold',
    fontSize: '18px',
    margin: '0px 0 30px 0', // Adjust this value for more space below the heading
  },
  orderContainer: {
    border: '0.8px solid lightgray',
    borderRadius: '10px',
    marginTop: '10px',
    marginLeft: '8px',
    boxShadow: '0 4px 4px rgba(0, 0, 0, 0.1)',
    minWidth: '300px',
  },
  innerContainer: {
    padding: '12px',
  },
  iconButton: {
    padding: '5px',
  },
  icon: {
    fontSize: '18px',
    color: '#b4b4b4',
  },
  tooltip: {
    fontSize: '13px',
  },
});

const DropdownField = ({ label, value, onChange, options, disabled, classes, includeNone }) => (
  <FormControl
    className={`${classes.fieldContainer} ${classes.formControl}`}
    fullWidth
    variant="outlined"
  >
    <InputLabel id={`${label}-label`} style={{ fontSize: 14 }}>{label}</InputLabel>
    <Select
      labelId={`${label}-label`}
      id={label}
      value={value || ''}
      onChange={onChange}
      style={{ fontSize: 14, maxHeight: '32px' }}
      disabled={disabled}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
        PaperProps: {
          style: {
            maxHeight: 300, // Set maximum height for the dropdown menu
            overflowY: 'auto', // Enable vertical scrolling
          },
        },
      }}
    >
      {includeNone && (
        <MenuItem value="" style={{ fontSize: 14, padding: '8px 10px' }}>None</MenuItem>
      )}
      {options.map((option, i) => (
        <MenuItem key={i} value={option} style={{ fontSize: 14, padding: '3px 10px' }}>
          {option}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

const getOptions = (key, protoIpam, protoMask, regions) => {
  if (['mask', 'pod_mask', 'svc_mask'].includes(key)) return protoMask;
  if (key === 'region') return Object.keys(regions);
  if (['subnet', 'pod', 'svc', 'master'].includes(key)) return protoIpam;
  return [];
};

export default function OrderPage({ gatewayUrl, protoUrl, target, action, icon, id }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isRequestComplete, setIsRequestComplete] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);
  const [products, setProducts] = useState({});
  const [fields, setFields] = useState({});
  const [protoData, setProtoData] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [preventDestroy, setPreventDestroy] = useState(false);
  const [protoLoading, setProtoLoading] = useState(true);

  const handleNavigate = (route) => {
    navigate(route);
  };

  const getErrorMessage = (errorData) => {
    let current = errorData;
    while (current) {
      if (typeof current === 'string') {
        return current;
      }
      if (current.detail) {
        current = current.detail;
      } else if (Array.isArray(current) && current.length > 0 && current[0].msg) {
        return `${current[0].loc[1].toUpperCase()}: ${current[0].msg}`;
      } else {
        break;
      }
    }
    return 'An unknown error occurred';
  };

  const getProtoData = async () => {
    try {
      const response = await fetch(`${protoUrl}/`, {
        method: 'GET',
        headers,
      });
      const data = await response.json();
      setProtoData(data);
      setProtoLoading(false);
    } catch (err) {
      console.error(err);
      setProtoLoading(false);
    }
  };

  useEffect(() => {
    getProtoData();
  }, []);

  const productMap = protoData?.proto_products?.[target]?.products || [];
  const regions = protoData?.regions || [];
  const protoIpam = protoData?.proto_ipam || [];
  const protoMask = protoData?.proto_mask || [];

  // Group products by subcategory
  const groupedProducts = productMap.reduce((acc, product) => {
    const { subcategory } = product;
    if (!acc[subcategory]) {
      acc[subcategory] = [];
    }
    acc[subcategory].push(product);
    return acc;
  }, {});

  // Additional state for storing configArray
  const [configArray, setConfigArray] = useState([]);

  // required since protoData is not available on first render
  // when protoData from the calling function, it will be available on the next render
  useEffect(() => {
    const configDataMap = protoData?.data_models?.[target] || {};

    const newConfigArray = Object.keys(configDataMap).map((key) => ({
      key,
      value: configDataMap[key].gui.sample,
      display: configDataMap[key].gui.form,
      display_name: configDataMap[key].display_name,
      description: configDataMap[key].description,
      type: configDataMap[key].type,
    }));

    setConfigArray(newConfigArray);
    const initialState = newConfigArray.reduce((acc, item) => {
      acc[item.key] = item.value;
      return acc;
    }, {});
    setFields(initialState);
  }, [protoData, target]);

  const initialState = configArray.reduce((acc, item) => {
    acc[item.key] = item.value;
    return acc;
  }, {});

  // Add product selections to initial state
  Object.keys(groupedProducts).forEach((key) => {
    initialState[key] = '';
  });

  useEffect(() => {
    const updatedProducts = Object.keys(fields)
      .filter((key) => (
        Object.values(groupedProducts).flat().some((product) => product.product_name === fields[key] && fields[key] !== '')
      ))
      .reduce((acc, key) => ({ ...acc, [key]: fields[key] }), {});

    // Only update the state if it has actually changed
    if (JSON.stringify(updatedProducts) !== JSON.stringify(products)) {
      setProducts(updatedProducts);
    }
  }, [fields, groupedProducts, products]);

  const handleChange = (event, key) => {
    setFields({
      ...fields,
      [key]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    setResponseMessage(null);
    setIsRequestComplete(false);
    setIsButtonDisabled(true);

    // only include fields in the request body that have item.display === true
    const filteredFields = Object.keys(fields)
      .filter((key) => configArray.find((item) => item.key === key && item.display))
      .reduce((acc, key) => {
        const obj = { ...acc };
        obj[key] = fields[key];
        return obj;
      }, {});

    const body = {
      ...filteredFields,
      products,
      action,
      stage: 'order',
    };

    if (preventDestroy) {
      body.prevent_destroy = true;
    }

    if (['install', 'test'].includes(action)) {
      try {
        const response = await fetch(`${gatewayUrl}/`, {
          method: 'POST',
          headers,
          body: JSON.stringify(body),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(JSON.stringify(getErrorMessage(errorData)));
        }

        const responseData = await response.json();
        setResponseMessage(responseData);
        setIsRequestComplete(true);
      } catch (err) {
        const errorData = JSON.parse(err.message);
        if (errorData.detail && Array.isArray(errorData.detail) && errorData.detail[0]?.msg) {
          setError(`${errorData.detail[0].loc[1].toUpperCase()}:  ${errorData.detail[0].msg}`);
        } else {
          setError(err.message);
        }
      }
    } else if (action === 'update') {
      try {
        const response = await fetch(`${gatewayUrl}/${id}`, {
          method: 'PATCH',
          headers,
          body: JSON.stringify(body),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(JSON.stringify(getErrorMessage(errorData)));
        }

        const responseData = await response.json();
        setResponseMessage(responseData);
        setIsRequestComplete(true);
      } catch (err) {
        const errorData = JSON.parse(err.message);
        if (errorData.detail && Array.isArray(errorData.detail) && errorData.detail[0]?.msg) {
          setError(`Error:  ${errorData.detail[0].msg}`);
        } else {
          setError(err.message);
        }
      }
    } else if (action === 'decom') {
      try {
        const response = await fetch(`${gatewayUrl}/${id}?stage=order`, {
          method: 'DELETE',
          headers,
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(JSON.stringify(getErrorMessage(errorData)));
        }

        const responseData = await response.json();
        setResponseMessage(responseData);
        setIsRequestComplete(true);
      } catch (err) {
        const errorData = JSON.parse(err.message);
        if (errorData.detail && Array.isArray(errorData.detail) && errorData.detail[0]?.msg) {
          setError(`Error:  ${errorData.detail[0].msg}`);
        } else {
          setError(err.message);
        }
      }
    }
    setLoading(false);
  };

  return (
    <div className="m-2 md:m-2 mt-24 p-2 md:p-8">
      <Header
        title={(
          <div style={{ display: 'inline-flex', alignItems: 'center' }}>
            <button
              type="button"
              onClick={() => handleNavigate(`/${target}`)}
              className="hover-button rounded-sm px-px border border-gray-300"
            >
              {target}
            </button>
            <PiCaretRightBold className="inline-element" style={{ margin: '0 8px' }} />
            <span className="inline-element">{action}</span>
          </div>
        )}
      />
      {protoLoading ? (
        <div className="flex justify-start items-center" style={{ marginLeft: '14px' }}>
          <BarLoader color="#007aff" height={4} width={150} />
        </div>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={14} sm={6} className={`${classes.root} ${classes.orderContainer}`}>
              <div className={classes.innerContainer}>
                <Typography className={classes.configHeader}>
                  Configuration
                </Typography>
                {configArray.map((item) => {
                  if (item.display) {
                    const isDisabled = (action === 'decom') || (action === 'update' && item.type !== 'optional');
                    const options = getOptions(item.key, protoIpam, protoMask, regions);
                    return (
                      <Grid item xs={12} className={classes.fieldContainer} key={item.key}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {options.length > 0 ? (
                            <DropdownField
                              label={item.display_name}
                              value={fields[item.key]}
                              onChange={(event) => handleChange(event, item.key)}
                              options={options}
                              disabled={isDisabled}
                              classes={classes}
                              includeNone
                            />
                          ) : (
                            <TextField
                              label={item.display_name}
                              value={fields[item.key]}
                              onChange={(event) => handleChange(event, item.key)}
                              fullWidth
                              variant="outlined"
                              className={`${classes.fieldContainer} ${classes.formControl}`}
                              InputLabelProps={{ style: { fontSize: 14 } }}
                              InputProps={{ style: { fontSize: 14 } }}
                              required={item.type === 'required'}
                              disabled={isDisabled}
                              error={item.type === 'required' && !fields[item.key]}
                              helperText={item.type === 'required' && !fields[item.key] ? `${item.key} is required` : ''}
                            />
                          )}
                          <Tooltip title={`${item.description}`} arrow classes={{ tooltip: classes.tooltip }}>
                            <IconButton className={classes.iconButton}>
                              <HelpOutlineIcon className={classes.icon} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </Grid>
                    );
                  }
                  return null;
                })}
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={preventDestroy}
                      onChange={(e) => setPreventDestroy(e.target.checked)}
                      name="preventDestroy"
                      color="primary"
                    />
                  )}
                  label={(
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography style={{ fontSize: '14px' }}>
                        Prevent Destroy
                      </Typography>
                      <Tooltip title="This will prevent the resource from being destroyed." arrow>
                        <IconButton size="small" style={{ marginLeft: '4px' }}>
                          <HelpOutlineIcon style={{ fontSize: '16px' }} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={14} sm={6} className={`${classes.root} ${classes.orderContainer}`}>
              <div className={classes.innerContainer}>
                <Typography className={classes.configHeader}>
                  Products
                </Typography>
                {Object.entries(groupedProducts).map(([subcategory, productNames]) => {
                  const visibleProducts = productNames.filter((product) => !product.hide);
                  if (visibleProducts.length === 0) return null;
                  return (
                    <Grid item xs={12} key={subcategory}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <DropdownField
                          label={subcategory}
                          value={fields[subcategory]}
                          onChange={(event) => handleChange(event, subcategory)}
                          options={visibleProducts.map((product) => product.product_name)}
                          disabled={action === 'decom'}
                          classes={classes}
                          includeNone
                        />
                        <Tooltip title={`Information about ${subcategory}`} arrow classes={{ tooltip: classes.tooltip }}>
                          <IconButton className={classes.iconButton}>
                            <HelpOutlineIcon className={classes.icon} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </Grid>
                  );
                })}
              </div>
            </Grid>
          </Grid>
          <ButtonAction
            bgColor={getBgColor(action)}
            icon={getIcon(icon)}
            text={action}
            onClick={handleSubmit}
            disabled={isButtonDisabled && !error}
          />
          <LoadingOrder
            isLoading={loading}
            isRequestComplete={isRequestComplete}
            message={responseMessage}
            error={error}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" style={{ fontSize: '14px', display: 'flex', alignItems: 'center' }}>
              <a
                href={`/${target}/logs`}
                style={{ textDecoration: 'underline', color: 'blue', display: 'flex', alignItems: 'center' }}
              >
                <ListAltIcon style={{ marginRight: '5px' }} />
                Logs
              </a>
            </Typography>
          </div>
        </>
      )}
    </div>
  );
}
