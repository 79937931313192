import React, { useEffect, useState, useRef } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Resize, Selection, Inject, Edit, Toolbar, Sort, Filter, ExcelExport, PdfExport, ContextMenu } from '@syncfusion/ej2-react-grids';
import { BarLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';
import { contextMenuItems } from '../data/general';
import { Header } from '.';
import { ButtonPage } from './Buttons';
import { getBgColor, getIcon } from './Format';

const HEADER = process.env.REACT_APP_HEADER;
const GATEWAY_HOST = process.env.REACT_APP_ENV === 'prod'
  ? 'https://gateway.imesh.cloudtuple.com'
  : 'http://127.0.0.1:8000';

const Target = ({ target, TargetGrid }) => {
  const GATEWAY_URL = `${GATEWAY_HOST}/${target}/`;
  const gridRef = useRef(null);
  const navigate = useNavigate();
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const selectionsettings = {
    checkboxOnly: true,
    persistSelection: true,
    checkboxMode: 'ResetOnRowClick',
    enableToggle: true,
  };

  const toolbarOptions = ['Edit', 'Delete', 'Search'];

  const sortingOptions = {
    columns: [{ field: 'timestamp', direction: 'Descending' }],
  };

  const toolbarClick = (args) => {
    const newArgs = { ...args };
    if (newArgs.item.id === 'gridcomp_edit') { navigate(`/${target}/update`, { state: { rowData: selectedRowData } }); newArgs.cancel = true; }
    if (newArgs.item.id === 'gridcomp_delete') { navigate(`/${target}/decom`, { state: { rowData: selectedRowData } }); newArgs.cancel = true; }
  };

  const handleRowSelected = (args) => {
    setSelectedRowData(args.data);
  };

  const editing = {
    allowEditing: false,
    allowDeleting: false,
    editMode: 'externalform',
  };

  const fetchData = async () => {
    setLoading(true);
    const headers = {
      'X-Cloudtuple': HEADER,
    };
    const response = await fetch(`${GATEWAY_URL}`, {
      headers,
    });
    const fetchedData = await response.json();
    setData(fetchedData);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 300000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="m-2 md:m-2 mt-24 p-2 md:p-8">
      <Header category="Infrastructure" title={target} />
      <div className="flex justify-start mb-4">
        <ButtonPage
          navigateTo={`/${target}/test`}
          text="Test"
          icon={getIcon('test')}
          bgColor={getBgColor('button')}
          hoverColor={getBgColor('test')}
          textColor="#666666"
        />
        <ButtonPage
          navigateTo={`/${target}/install`}
          text="Install"
          icon={getIcon('install')}
          bgColor={getBgColor('button')}
          hoverColor={getBgColor('install')}
          textColor="#666666"
        />
        <ButtonPage
          navigateTo={`/${target}/logs`}
          text="LOGS"
          icon={getIcon('logs')}
          bgColor={getBgColor('button')}
          hoverColor={getBgColor('logs')}
          textColor="#666666"
        />
      </div>

      {loading ? (
        <div className="flex justify-center items-center">
          <BarLoader color="#007aff" height={4} width={150} />
        </div>
      ) : (
        <GridComponent
          ref={gridRef}
          id="gridcomp"
          // width="1200px"
          dataSource={data}
          enableHover={false}
          allowPaging
          allowSorting
          pageSettings={{ pageCount: 5, pageSize: 10 }}
          selectionSettings={selectionsettings}
          toolbar={toolbarOptions}
          toolbarClick={toolbarClick}
          allowExcelExport
          allowPdfExport
          contextMenuItems={contextMenuItems}
          editSettings={editing}
          rowSelected={handleRowSelected}
          sortSettings={sortingOptions}
        >
          <ColumnsDirective>
            {TargetGrid.map((item, index) => (
              item.display ? (
                <ColumnDirective
                  key={index}
                  type={item.type}
                  field={item.field}
                  headerText={item.headerText}
                  width={item.width}
                  format={item.format}
                  textAlign={item.textAlign}
                  template={
                    item.template && typeof item.template === 'function'
                      ? item.template
                      : undefined
                  }
                />
              ) : null
            ))}
          </ColumnsDirective>
          <Inject
            services={[Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Selection, PdfExport, Toolbar, Edit]}
          />
        </GridComponent>
      )}
    </div>
  );
};

export default Target;
