import React from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Box, Card, CardContent, CardMedia } from '@mui/material';
import { Header } from '.';

const CatalogueDetail = () => {
  const location = useLocation();
  const { state } = location;

  // If state or product is null, display a message
  if (!state || !state.product || !state.details || !state.imageUrl) {
    return (
      <div className="m-2 md:m-2 mt-24 p-2 md:p-8">
        <Header category="Catalogue" title="Product Details" />
        <Box display="flex" justifyContent="center">
          <Typography variant="h4" component="div">
            No product details available.
          </Typography>
        </Box>
      </div>
    );
  }

  const { product, details, imageUrl } = state;

  return (
    <div className="m-2 md:m-2 mt-24 p-2 md:p-8">
      <Header category="Catalogue" title="Product Details" />
      <Box display="flex" justifyContent="center">
        <Card sx={{ maxWidth: 600 }}>
          <CardMedia
            component="img"
            height="300"
            image={imageUrl}
            alt={product}
          />
          <CardContent>
            <Typography variant="h4" component="div">
              {product}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              ${details.price}
            </Typography>
            <Typography variant="body1" color="text.secondary" style={{ marginTop: '14px' }}>
              {details.description}
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default CatalogueDetail;
