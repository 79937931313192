import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BarLoader } from 'react-spinners';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  loadingText: {
    marginTop: '20px',
    marginLeft: '0px',
    marginRight: '40px',
    marginBottom: '20px',
  },
  loaderContainer: {
    minHeight: '4px',
    display: 'flex',
    alignItems: 'center',
  },
  checkIconGreen: {
    marginRight: '10px',
    color: 'green',
  },
  checkIconRed: {
    marginRight: '10px',
    color: 'red',
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  loaderMargin: {
    marginLeft: '10px',
  },
});

const Loading = ({ isLoading, isRequestComplete, message, error }) => {
  const classes = useStyles();

  return (
    <Grid item className={classes.loadingText}>
      <div className={classes.loaderContainer}>
        {(() => {
          if (isLoading) {
            return (
              <div className={classes.flexContainer}>
                <Typography variant="body1" style={{ marginLeft: '8px', fontSize: '14px' }} display="inline"> </Typography>
                <div className={classes.loaderMargin}>
                  <BarLoader loading={isLoading} />
                </div>
              </div>
            );
          }
          if (isRequestComplete) {
            return (
              <div className={classes.flexContainer}>
                <Typography variant="body1" style={{ marginLeft: '8px', fontSize: '14px' }} display="inline"> </Typography>
              </div>
            );
          }
          return null;
        })()}
      </div>
      {message && message.build_uri && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1" style={{ marginLeft: '8px', fontSize: '14px' }}>
            <a
              href={message.build_uri}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline', color: 'blue' }}
            >
              Job
            </a>
            {' '}created
          </Typography>
        </div>
      )}
      {error && (
        <Typography variant="body1" color="error">{error}</Typography>
      )}
    </Grid>
  );
};

export default Loading;
