import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardContent, CardMedia, Typography, Button, Grid, Tabs, Tab, Box } from '@mui/material';
import { Header } from '.';
import Loading from './Loading';
import { transformString } from './Utils';

const HEADER = process.env.REACT_APP_HEADER;
const headers = {
  'Content-Type': 'application/json',
  'X-Cloudtuple': HEADER,
};

export const CataloguePage = ({ protoUrl }) => {
  // State to hold product data, loading status, and errors
  const [protoProducts, setProtoProducts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isRequestComplete, setIsRequestComplete] = useState(false);
  const [error, setError] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);

  const { category: urlCategory } = useParams();
  const navigate = useNavigate();

  // Function to fetch product data from the provided URL
  const getProtoData = async () => {
    try {
      const response = await fetch(`${protoUrl}/`, {
        method: 'GET',
        headers,
      });
      const data = await response.json();
      setProtoProducts(data.proto_products);
      setIsRequestComplete(true);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Effect to fetch product data on component mount or when protoUrl changes
  useEffect(() => {
    getProtoData();
  }, [protoUrl]);

  // Effect to update the selected tab based on URL parameter
  useEffect(() => {
    if (!urlCategory) {
      navigate('/catalogue/host');
    } else {
      const categoryIndex = Object.keys(protoProducts || {}).indexOf(urlCategory);
      if (categoryIndex !== -1) {
        setSelectedTab(categoryIndex);
      }
    }
  }, [urlCategory, protoProducts, navigate]);

  // Function to handle tab change event
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    const selectedCategory = Object.keys(protoProducts)[newValue].toLowerCase();
    navigate(`/catalogue/${selectedCategory}`);
  };

  // Function to render a single product card
  // It displays product details such as image, name, price, and description
  const renderProductCard = (productCategory, product) => {
    const imageUrl = protoProducts[productCategory].image;
    return (
      <Grid item key={product.product_name} xs={12} sm={6} md={4}>
        <Card sx={{ maxWidth: 300, maxHeight: 480 }}>
          <CardMedia
            component="img"
            height="140"
            image={imageUrl}
            alt={product.product_name}
          />
          <CardContent>
            <Typography gutterBottom variant="h8" component="div">
              {transformString(product.subcategory)}
            </Typography>
            <Typography variant="body1" color="text.secondary" style={{ fontWeight: 'bold', fontSize: '14px', textTransform: 'uppercase' }}>
              {product.product_name}
            </Typography>
            <Typography variant="body1" color="text.secondary" style={{ fontSize: '14px' }}>
              ${product.price}
            </Typography>
            <Typography variant="body1" color="text.secondary" style={{ fontSize: '14px', marginTop: '14px' }}>
              {product.description}
            </Typography>
            <div style={{ marginTop: '4px' }}>
              <Button
                size="small"
                color="primary"
                onClick={() => navigate(`/catalogue/${productCategory.toLowerCase()}/${product.subcategory.toLowerCase().replace(/_/g, '-')}/${product.product_name.toLowerCase()}`, {
                  state: {
                    product: product.product_name,
                    details: product,
                    imageUrl,
                  },
                })}
              >
                Details
              </Button>
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  // Function to render products under a category
  // It filters out hidden products and displays the rest
  const renderCategory = (productCategory, data) => (
    <div key={productCategory}>
      <Grid container spacing={4}>
        {data.products && data.products.filter((product) => !product.hide).map((product) => (
          renderProductCard(
            productCategory,
            product,
          )
        ))}
      </Grid>
    </div>
  );

  // Main return block to render the catalogue page
  // It includes the header, loading state, and product tabs
  return (
    <div className="m-2 md:m-2 mt-24 p-2 md:p-8">
      <Header category="Catalogue" title="Products" />
      <Loading
        isLoading={loading}
        isRequestComplete={isRequestComplete}
        error={error}
      />
      {!loading && protoProducts && (
        <Box>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="product categories"
          >
            {Object.keys(protoProducts).map((productCategory, index) => (
              <Tab key={index} label={protoProducts[productCategory].display_name} />
            ))}
          </Tabs>
          {Object.keys(protoProducts).map((productCategory, index) => (
            <div
              role="tabpanel"
              hidden={selectedTab !== index}
              id={`tabpanel-${index}`}
              aria-labelledby={`tab-${index}`}
              key={index}
            >
              {selectedTab === index && (
                <Box p={3}>
                  {renderCategory(productCategory, protoProducts[productCategory])}
                </Box>
              )}
            </div>
          ))}
        </Box>
      )}
    </div>
  );
};
