import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { getUiColor, getUiStageIcon, getUiActionIcon, getUiBuildIcon, getUiBuildStatusIcon, getUiDownloadIcon } from '../components/Format';

const HEADER = process.env.REACT_APP_HEADER;
const headers = {
  'Content-Type': 'application/json',
  'X-Cloudtuple': HEADER,
};
const GATEWAY_HOST = process.env.REACT_APP_ENV === 'prod'
  ? 'https://gateway.imesh.cloudtuple.com'
  : 'http://127.0.0.1:8000';

const fetchData = async (buildUri, setBuildStatus) => {
  if (buildUri) {
    const buildId = buildUri.split('/').pop().split('?')[0];
    try {
      const response = await fetch(`${GATEWAY_HOST}/connect/buildstatus/${buildId}`, {
        method: 'GET',
        headers,
      });
      const data = await response.json();
      setBuildStatus(data.build_status);
    } catch (error) {
      setBuildStatus(error.message);
    }
  }
};

const stageTemplate = (props) => {
  const StageIcon = getUiStageIcon(props.stage, props.action);
  const iconSize = '16px';
  const color = getUiColor(props.stage, props.action);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <StageIcon style={{ color, fontSize: iconSize, marginRight: '4px' }} />
      <span>{props.stage}</span>
    </div>
  );
};

const actionTemplate = (props) => {
  const iconSize = '16px';
  const status = props.action;
  const color = getUiColor(props.stage, props.action);
  const ActionIcon = getUiActionIcon(props.stage, props.action);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <ActionIcon style={{ color, fontSize: iconSize, marginRight: '4px' }} />
      <span>{status}</span>
    </div>
  );
};

const buildUriTemplate = (props) => {
  if (!props.build_uri) {
    return null;
  }

  const BuildIcon = getUiBuildIcon(props.stage, props.action);
  const color = getUiColor(props.stage, props.action);

  return (
    <a
      href={props.build_uri}
      target="_blank"
      rel="noopener noreferrer"
      className={`underline flex items-center space-x-1 ${color ? '' : 'text-blue-500'}`}
      style={color ? { color } : {}}
    >
      <BuildIcon size={18} />
      <span>log</span>
    </a>
  );
};

const downloadUriTemplate = (props) => {
  if (!props.download_uri) {
    return null;
  }

  const DownloadIcon = getUiDownloadIcon(props.stage, props.action);
  const color = getUiColor(props.stage, props.action);

  return (
    <a
      href={props.download_uri}
      target="_blank"
      rel="noopener noreferrer"
      className={`underline flex items-center space-x-1 ${color ? '' : 'text-blue-500'}`}
      style={color ? { color } : {}}
    >
      <DownloadIcon size={18} />
      <span>zip</span>
    </a>
  );
};

const statusTemplate = (props) => {
  const [buildStatus, setBuildStatus] = useState('');

  useEffect(() => {
    fetchData(props.build_uri, setBuildStatus);
  }, [props.stage, props.build_uri]);

  if (buildStatus === 'WORKING') {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CircularProgress style={{ width: '16px', height: '16px', marginRight: '4px' }} />
        <span>Executing</span>
      </div>
    );
  }

  const StatusIcon = getUiBuildStatusIcon(props.stage, props.action, buildStatus);

  if (props.stage === 'deployed') {
    const LiveStatusIcon = getUiBuildStatusIcon(props.stage, props.action, 'SUCCESS');
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <LiveStatusIcon style={{ color: getUiColor(props.stage, props.action), fontSize: '16px', marginRight: '4px' }} />
        <span>SUCCESS</span>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <StatusIcon style={{ color: getUiColor(props.stage, props.action), fontSize: '16px', marginRight: '4px' }} />
      <span>{buildStatus}</span>
    </div>
  );
};

const updateIdTemplate = (props) => {
  const url = `/infra/${props.oid_update}`;
  return (
    <Link to={url} className="text-blue-500 underline cursor-pointer">
      {props.oid_update}
    </Link>
  );
};

const timeTemplate = (props) => {
  const date = new Date(props.timestamp * 1000);
  return new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }).format(date);
};

const timeUpdatedTemplate = (props) => {
  if (!props.timestamp_updated) {
    return null;
  }
  const date = new Date(props.timestamp_updated * 1000);
  return new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }).format(date);
};

const codeTemplate = (props) => {
  const url = `/connect/${props.id}`;
  return (
    <Link to={url} className="text-blue-500 underline cursor-pointer">
      {props.connect_code}
    </Link>
  );
};

export const ConnectGrid = [
  {
    type: 'checkbox',
    display: false,
    width: '40',
  },
  {
    field: 'connect_code',
    display: true,
    headerText: 'VPN Code',
    width: '150',
    textAlign: 'Left',
    template: codeTemplate,
  },
  {
    field: 'id',
    display: true,
    headerText: 'ID',
    width: '100',
    textAlign: 'Left',
  },
  {
    field: 'stage',
    display: true,
    headerText: 'Stage',
    width: '100',
    textAlign: 'Left',
    template: stageTemplate,
  },
  {
    field: 'action',
    display: true,
    headerText: 'Action',
    width: '100',
    textAlign: 'Left',
    template: actionTemplate,
  },
  {
    field: 'build_status',
    display: true,
    headerText: 'Status',
    width: '120',
    textAlign: 'Left',
    template: statusTemplate,
  },
  {
    field: 'peer_site',
    display: true,
    headerText: 'B End',
    width: '100',
    textAlign: 'Left',
  },
  {
    field: 'user_code',
    display: false,
    headerText: 'User Code',
    width: '100',
    textAlign: 'Left',
  },
  {
    field: 'region',
    display: true,
    headerText: 'Region',
    width: '100',
    textAlign: 'Left',
  },
  {
    field: 'ip0',
    display: false,
    headerText: 'IP0',
    width: '100',
    textAlign: 'Left',
  },
  {
    field: 'ip1',
    display: false,
    headerText: 'IP1',
    width: '100',
    textAlign: 'Left',
  },
  {
    field: 'build_uri',
    display: true,
    headerText: 'Build',
    width: '100',
    textAlign: 'Left',
    template: buildUriTemplate,
  },
  {
    field: 'download_uri',
    display: true,
    headerText: 'Files',
    width: '100',
    textAlign: 'Left',
    template: downloadUriTemplate,
  },
  {
    field: 'oid_update',
    display: true,
    headerText: 'Update ID',
    width: '120',
    textAlign: 'Left',
    template: updateIdTemplate,
  },
  {
    field: 'timestamp_updated',
    display: true,
    headerText: 'Updated',
    width: '120',
    textAlign: 'Left',
    template: timeUpdatedTemplate,
  },
  {
    field: 'timestamp',
    display: true,
    headerText: 'Created',
    width: '120',
    textAlign: 'Left',
    template: timeTemplate,
  },
  {
    field: 'contact_email',
    display: false,
    headerText: 'Email',
    width: '100',
    textAlign: 'Left',
  },
];

