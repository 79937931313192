import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Navbar, Sidebar, ThemeSettings } from './components';
import { Infra, Connect, Security } from './pages/targets/Target';
import { InfraDetail, ConnectDetail, SecurityDetail } from './pages/targets/TargetDetail';
import { InfraTest, ConnectTest, SecurityTest } from './pages/targets/TargetTest';
import { InfraInstall, ConnectInstall, SecurityInstall } from './pages/targets/TargetInstall';
import { InfraLogs, ConnectLogs, SecurityLogs } from './pages/targets/TargetLogs';
import { InfraUpdate, ConnectUpdate, SecurityUpdate } from './pages/targets/TargetUpdate';
import { InfraDecom, ConnectDecom, SecurityDecom } from './pages/targets/TargetDecom';
import { Catalogue } from './pages/store/Catalogue';
import CatalogueDetail from './components/CatalogueDetail';
import './App.css';
import { useStateContext } from './contexts/ContextProvider';

const App = () => {
  const { setCurrentColor, setCurrentMode, currentMode, activeMenu, currentColor, themeSettings, setThemeSettings } = useStateContext();

  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      <BrowserRouter>
        <div className="flex relative dark:bg-main-dark-bg">
          <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}>
            <TooltipComponent
              content="Settings"
              position="Top"
            >
              <button
                type="button"
                onClick={() => setThemeSettings(true)}
                style={{ background: currentColor, borderRadius: '50%' }}
                className="text-xl text-white p-2 hover:drop-shadow-xl hover:bg-light-gray"
                aria-label="Settings"
              >
                <FiSettings />
              </button>
            </TooltipComponent>
          </div>
          {activeMenu ? (
            <div className="text-sm w-46 fixed sidebar dark:bg-secondary-dark-bg bg-gray-200">
              <Sidebar />
            </div>
          ) : (
            <div className="w-0 dark:bg-secondary-dark-bg">
              <Sidebar />
            </div>
          )}
          <div
            className={
              activeMenu
                ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-44 w-full  '
                : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
            }
          >
            <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full h-12">
              <Navbar />
            </div>
            <div>
              {themeSettings && (<ThemeSettings />)}
              <Routes>
                {/* targets */}
                <Route path="/infra" element={<Infra />} />
                <Route path="/infra/:id" element={<InfraDetail />} />
                <Route path="/connect" element={<Connect />} />
                <Route path="/connect/:id" element={<ConnectDetail />} />
                <Route path="/security" element={<Security />} />
                <Route path="/security/:id" element={<SecurityDetail />} />

                {/* store */}
                <Route path="/catalogue" element={<Catalogue />} />
                <Route path="/catalogue/:category" element={<Catalogue />} />
                <Route path="/catalogue/:category/:subcategory/:id" element={<CatalogueDetail />} />

                {/* config */}
                <Route path="/infra/test" element={<InfraTest />} />
                <Route path="/infra/install" element={<InfraInstall />} />
                <Route path="/infra/logs" element={<InfraLogs />} />
                <Route path="/infra/update/:id" element={<InfraUpdate />} />
                <Route path="/infra/decom/:id" element={<InfraDecom />} />
                <Route path="/connect/test" element={<ConnectTest />} />
                <Route path="/connect/install" element={<ConnectInstall />} />
                <Route path="/connect/logs" element={<ConnectLogs />} />
                <Route path="/connect/update/:id" element={<ConnectUpdate />} />
                <Route path="/connect/decom/:id" element={<ConnectDecom />} />
                <Route path="/security/test" element={<SecurityTest />} />
                <Route path="/security/install" element={<SecurityInstall />} />
                <Route path="/security/logs" element={<SecurityLogs />} />
                <Route path="/security/update/:id" element={<SecurityUpdate />} />
                <Route path="/security/decom/:id" element={<SecurityDecom />} />
              </Routes>
            </div>
            {/* <Footer /> */}
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
