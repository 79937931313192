import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';

export const ButtonAction = ({
  bgColor,
  color = 'white',
  text,
  width,
  borderRadius = '6px',
  onClick,
  disabled,
  size = 'xs',
}) => (
  <button
    type="button"
    onClick={onClick || (() => { })}
    style={{
      backgroundColor: disabled ? 'lightgray' : bgColor,
      color: disabled ? 'black' : color,
      marginTop: '10px',
      marginRight: '20px',
      marginLeft: '0px',
      borderRadius,
      minWidth: '100px',
      padding: '6px 12px',
    }}
    className={`text-${size} p-3 w-${width} hover:outline hover:outline-2 hover:outline-gray-500`}
    disabled={disabled}
  >
    <b>{text.toUpperCase()}</b>
  </button>
);

export const ButtonPage = ({
  navigateTo,
  icon,
  bgColor = 'white',
  color = '#4C4C4C',
  text,
  width,
  borderRadius = '6px',
  marginBottom = '0px',
  size = 'xs',
}) => {
  const navigate = useNavigate();

  return (
    <button
      type="button"
      onClick={() => navigate(navigateTo)}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: bgColor,
        color,
        marginRight: '20px',
        marginBottom,
        borderRadius,
        padding: '6px 12px',
        border: 'none',
      }}
      className={`text-${size} p-3 w-${width} button-${text.toLowerCase()}`}
    >
      {icon} <b>{text.toUpperCase()}</b>
    </button>
  );
};
