import React, { useEffect, useState } from 'react';
import { Divider, Tabs, Tab, Box, Card, CardContent, CardMedia, Typography, Button, Grid, IconButton } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { BarLoader } from 'react-spinners';
import { ContentCopy } from '@mui/icons-material';
import { Header } from '.';
import { ButtonPage } from './Buttons';
import { getBgColor, getIcon } from './Format';

const HEADER = process.env.REACT_APP_HEADER;
const headers = {
  accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Cloudtuple': HEADER,
};

const GATEWAY_HOST = process.env.REACT_APP_ENV === 'prod'
  ? 'https://gateway.imesh.cloudtuple.com'
  : 'http://127.0.0.1:8000';

const PROTO_URL = `${GATEWAY_HOST}/proto`;

const TargetDetail = ({ target, TargetGrid }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [protoProducts, setProtoProducts] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const [summaryTabValue, setSummaryTabValue] = useState(0);
  const GATEWAY_URL = `${GATEWAY_HOST}/${target}/${id}`;

  const fetchData = async () => {
    setLoading(true);
    const response = await fetch(GATEWAY_URL, {
      method: 'GET',
      headers,
    });
    const fetchedData = await response.json();
    setData(fetchedData);
    setLoading(false);
  };

  const fetchProtoData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${PROTO_URL}/`, {
        method: 'GET',
        headers,
      });
      if (!response.ok) {
        throw new Error('Failed to fetch');
      }
      const fetchedData = await response.json();
      setProtoProducts(fetchedData.proto_products);
    } catch (error) {
      console.error('Error:', error);
      // alert('Failed to fetch proto data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchProtoData();
  }, [id, target]);

  const formatDate = (epoch) => {
    if (!epoch) return '';
    const date = new Date(epoch * 1000);
    return date.toLocaleString('en-GB', { timeZone: 'UTC' });
  };

  const summaryData = Object.keys(data).map((key) => {
    const gridItem = TargetGrid.find((item) => item.field === key && item.showInSummaryData);
    return gridItem ? {
      key: gridItem.headerText,
      value: data[key],
    } : null;
  }).filter((item) => item !== null);

  const propertiesData = Object.keys(data).map((key) => {
    const gridItem = TargetGrid.find((item) => item.field === key && item.showInPropertiesData);
    return gridItem ? {
      key: gridItem.headerText,
      value: data[key],
    } : null;
  }).filter((item) => item !== null);

  const productsData = protoProducts[data.target_name]?.products.filter((product) => Object.values(data.products || {}).includes(product.product_name)) || [];

  const renderValue = (key, value) => {
    if (key === 'Build') {
      return <a href={value} target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>link</a>;
    }
    if (key === 'Files') {
      return <a href={value} target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>zip</a>;
    }
    if (key === 'Created' || key === 'Updated') {
      return formatDate(value);
    }
    return value;
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSummaryTabChange = (event, newValue) => {
    setSummaryTabValue(newValue);
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Copied to clipboard');
    }).catch((err) => {
      alert('Failed to copy text: ', err);
    });
  };

  const renderProductCard = (product) => {
    if (product.hide) return null;
    const productCategory = protoProducts[data.target_name.toLowerCase()];
    const imageUrl = productCategory?.image || 'https://via.placeholder.com/140';
    return (
      <Grid item xs={12} sm={6} md={4} key={product.product_name}>
        <Card sx={{ maxWidth: 300, maxHeight: 480 }}>
          <CardMedia
            component="img"
            height="140"
            image={imageUrl}
            alt={product.product_name}
          />
          <CardContent>
            <Typography gutterBottom variant="h8" component="div">
              {product.subcategory}
            </Typography>
            <Typography variant="body1" color="text.secondary" style={{ fontWeight: 'bold', fontSize: '14px', textTransform: 'uppercase' }}>
              {product.product_name}
            </Typography>
            <Typography variant="body1" color="text.secondary" style={{ fontSize: '14px' }}>
              ${product.price}
            </Typography>
            <Typography variant="body1" color="text.secondary" style={{ fontSize: '14px', marginTop: '14px' }}>
              {product.description}
            </Typography>
            <div style={{ marginTop: '4px' }}>
              <Button
                size="small"
                color="primary"
                onClick={() => navigate(`/catalogue/${data.target_name.toLowerCase()}/${product.subcategory.toLowerCase().replace(/_/g, '-')}/${product.product_name.toLowerCase()}`, {
                  state: {
                    product: product.product_name,
                    details: product,
                    imageUrl,
                  },
                })}
              >
                Details
              </Button>
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  return (
    <div className="m-2 md:m-2 mt-24 p-2 md:p-8">
      <Header category={target.toUpperCase()} title="Configuration" />
      <div className="flex justify-start mb-4">
        <ButtonPage
          navigateTo={`/${target}/update/${id}`}
          text="Edit"
          icon={getIcon('update')}
          bgColor={getBgColor('update')}
          color="black"
        />
        <ButtonPage
          navigateTo={`/${target}/decom/${id}`}
          text="Delete"
          icon={getIcon('decom')}
          bgColor={getBgColor('decom')}
          color="white"
        />
      </div>
      {loading ? (
        <div className="flex justify-center items-center">
          <BarLoader color="#007aff" height={4} width={150} />
        </div>
      ) : (
        <>
          <Divider style={{ margin: '4px 0' }} />
          <Tabs
            value={summaryTabValue}
            onChange={handleSummaryTabChange}
            TabIndicatorProps={{ style: { backgroundColor: '#1A97F5' } }}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="summary and maintenance tabs"
            style={{ marginBottom: '20px' }}
          >
            <Tab label="Summary" style={{ marginRight: '30px' }} />
            <Tab label="Maintenance" style={{ marginRight: '30px' }} />
          </Tabs>
          <Box style={{ minHeight: '260px', maxHeight: '260px', overflowY: 'auto' }}>
            {summaryTabValue === 0 && (
              <table style={{ width: '500px', borderCollapse: 'collapse', tableLayout: 'fixed' }}>
                <tbody>
                  {summaryData.map((item, index) => (
                    <tr key={index} style={{ border: 'none' }}>
                      <td style={{ padding: '8px', fontSize: '14px', border: 'none', width: '100px' }}>{item.key}</td>
                      <td style={{ padding: '8px', fontSize: '13px', border: 'none', width: '250px' }}>
                        {renderValue(item.key, item.value)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {summaryTabValue === 1 && (
              <div>
                {/* Add your maintenance content here */}
              </div>
            )}
          </Box>
          <Divider style={{ margin: '16px 0' }} />
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            TabIndicatorProps={{ style: { backgroundColor: '#1A97F5' } }}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="product categories"
            style={{ marginBottom: '20px' }}
          >
            <Tab label="Products" style={{ marginRight: '30px' }} />
            <Tab label="Properties" style={{ marginRight: '30px' }} />
            <Tab label="Output" style={{ marginRight: '30px' }} />
            <Tab label="JSON" style={{ marginRight: '30px' }} />
          </Tabs>
          <Box>
            {tabValue === 0 && (
              <Grid container spacing={2}>
                {productsData.map((product) => renderProductCard(product))}
              </Grid>
            )}
            {tabValue === 1 && (
              <table style={{ width: '500px', borderCollapse: 'collapse', tableLayout: 'fixed' }}>
                <tbody>
                  {propertiesData.map((item, index) => (
                    <tr key={index} style={{ border: 'none' }}>
                      <td style={{ padding: '8px', fontSize: '14px', border: 'none', width: '200px' }}>{item.key}</td>
                      <td style={{ padding: '8px', fontSize: '13px', border: 'none', width: '350px' }}>
                        {renderValue(item.key, item.value)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {tabValue === 2 && data.output && (
              <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', fontSize: '14px' }}>
                {JSON.stringify(data.output, null, 2)}
              </pre>
            )}
            {tabValue === 3 && (
              <Box>
                <IconButton onClick={() => handleCopyToClipboard(JSON.stringify(data, null, 2))} size="small">
                  <ContentCopy fontSize="small" />
                  <Typography variant="caption" style={{ marginLeft: '4px' }}>Copy to Clipboard</Typography>
                </IconButton>
                <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', fontSize: '14px', maxHeight: '400px', overflowY: 'auto' }}>
                  {JSON.stringify(data, null, 2)}
                </pre>
              </Box>
            )}
          </Box>
        </>
      )}
    </div>
  );
};

export default TargetDetail;
