import React from 'react';
import UpdateIcon from '@material-ui/icons/Update';
import OrderPage from '../../components/Order';

const GATEWAY_HOST = process.env.REACT_APP_ENV === 'prod'
  ? 'https://gateway.imesh.cloudtuple.com'
  : 'http://127.0.0.1:8000';
const PROTO_URL = `${GATEWAY_HOST}/proto`;

export const InfraTest = () => (
  <OrderPage
    gatewayUrl={`${GATEWAY_HOST}/infra`}
    protoUrl={PROTO_URL}
    target="infra"
    action="test"
    icon={<UpdateIcon />}
  />
);

export const ConnectTest = () => (
  <OrderPage
    gatewayUrl={`${GATEWAY_HOST}/connect`}
    protoUrl={PROTO_URL}
    target="connect"
    action="test"
    icon={<UpdateIcon />}
  />
);

export const SecurityTest = () => (
  <OrderPage
    gatewayUrl={`${GATEWAY_HOST}/security`}
    protoUrl={PROTO_URL}
    target="security"
    action="test"
    icon={<UpdateIcon />}
  />
);
