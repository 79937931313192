import React from 'react';
import Target from '../../components/Target';
import { InfraGrid } from '../../data/infra';
import { ConnectGrid } from '../../data/connect';
import { SecurityGrid } from '../../data/security';

const Infra = () => <Target target="infra" TargetGrid={InfraGrid} />;
const Connect = () => <Target target="connect" TargetGrid={ConnectGrid} />;
const Security = () => <Target target="security" TargetGrid={SecurityGrid} />;

export { Infra, Connect, Security };
