import React from 'react';
import { HiServer } from 'react-icons/hi';
import { AiFillAppstore, AiOutlineDisconnect } from 'react-icons/ai';
import { MdOutlineSecurity } from 'react-icons/md';
import { FaUser, FaNetworkWired, FaCloud } from 'react-icons/fa';
import { RiServerFill } from 'react-icons/ri';
import { FaBinoculars } from 'react-icons/fa6';

export const contextMenuItems = [
  'AutoFit',
  'AutoFitAll',
  'SortAscending',
  'SortDescending',
  'Copy',
  'Edit',
  'Delete',
  'Save',
  'Cancel',
  'PdfExport',
  'ExcelExport',
  'CsvExport',
  'FirstPage',
  'PrevPage',
  'LastPage',
  'NextPage',
];

export const links = [
  {
    title: 'Landing Zone',
    icon: <FaCloud />,
    links: [
      {
        name: 'host',
        display_name: 'Host',
        icon: <FaNetworkWired />,
      },
      {
        name: 'user',
        display_name: 'User',
        icon: <FaUser />,
      },
    ],
  },
  {
    title: 'Infrastructure',
    icon: <RiServerFill />,
    links: [
      {
        name: 'infra',
        display_name: 'Infra',
        icon: <HiServer />,
      },
      {
        name: 'connect',
        display_name: 'Connect',
        icon: <AiOutlineDisconnect />,
      },
      {
        name: 'security',
        display_name: 'Security',
        icon: <MdOutlineSecurity />,
      },
    ],
  },
  {
    title: 'Discover',
    icon: <FaBinoculars />,
    links: [
      {
        name: 'catalogue',
        display_name: 'Catalogue',
        icon: <AiFillAppstore />,
      },
    ],
  },
];

export const chatData = [
  {
    message: 'Install successful',
    desc: 'i-boot-euwe2 deployed successfully',
    image: 'https://via.placeholder.com/150',
    time: '9:08 AM',
  },
  {
    message: 'Update failed',
    desc: 'i-test-uewe2 update failed',
    image: 'https://via.placeholder.com/150',
    time: '11:56 AM',
  },
  {
    message: 'Delete successful',
    desc: 'i-bridge-euwe2 deployed successfully',
    image: 'https://via.placeholder.com/150',
    time: '9:08 AM',
  },
];

export const themeColors = [
  {
    name: 'blue-theme',
    color: '#1A97F5',
  },
  {
    name: 'red-theme',
    color: '#FF5C8E',
  },
  {
    name: 'indigo-theme',
    color: '#1E4DB7',
  },
];
