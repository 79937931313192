import React from 'react';
import { Link } from 'react-router-dom';
import { FaCheckCircle, FaRegCheckCircle, FaRegFile, FaFile } from 'react-icons/fa';
import { BsXCircleFill } from 'react-icons/bs';
import { AiOutlineExperiment } from 'react-icons/ai';
import { MdOutlineSystemUpdateAlt } from 'react-icons/md';
import { FiMove } from 'react-icons/fi';
import { CircularProgress } from '@material-ui/core';
import { getUiColor, getUiBuildIcon } from '../components/Format';

const buildUriTemplate = (props) => {
  const BuildIcon = getUiBuildIcon(props.stage, props.action);
  const color = getUiColor(props.stage, props.action);

  return (
    <a
      href={props.build_uri}
      target="_blank"
      rel="noopener noreferrer"
      className={`underline flex items-center space-x-1 ${color ? '' : 'text-blue-500'}`}
      style={color ? { color } : {}}
    >
      <BuildIcon size={18} />
      <span>log</span>
    </a>
  );
};

const downloadUriTemplate = (props) => {
  let icon = null;
  let color = '';

  if (props.download_uri) {
    if (props.stage === 'deployed' && props.action === 'install') {
      icon = <FaFile size={18} />;
    } else if (props.stage === 'deployed' && props.action === 'update') {
      icon = <FaFile size={18} />;
      color = '#E3963E';
    } else if (props.action === 'decom') {
      icon = <FaFile size={18} />;
      color = '#cc3300';
    } else {
      icon = <FaRegFile size={18} />;
    }

    return (
      <a
        href={props.download_uri}
        target="_blank"
        rel="noopener noreferrer"
        className={`underline flex items-center space-x-1 ${color ? '' : 'text-blue-500'}`}
        style={color ? { color } : {}}
      >
        {icon}
        <span>zip</span>
      </a>
    );
  }

  return null;
};

const statusTemplate = (props) => {
  const StatusIcon = props.action === 'install' ? FaCheckCircle : FaRegCheckCircle;

  if (props.stage === 'deployed') {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <StatusIcon style={{ color: '#3B82F6', fontSize: '16px', marginRight: '4px' }} />
        <span>Complete</span>
      </div>
    );
  }

  if (props.stage === 'order') {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CircularProgress size={16} style={{ color: '#3B82F6', marginRight: '4px' }} />
        <span>Deploying</span>
      </div>
    );
  }

  return null;
};

const codeTemplate = (props) => {
  const url = `/connect/${props.id}`;
  return (
    <Link to={url} className="text-blue-500 underline cursor-pointer">
      {props.connect_code}
    </Link>
  );
};

const timeTemplate = (props) => {
  const date = new Date(props.timestamp * 1000);
  return new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }).format(date);
};

const timeUpdatedTemplate = (props) => {
  if (!props.timestamp_updated) {
    return null;
  }
  const date = new Date(props.timestamp_updated * 1000);
  return new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }).format(date);
};

const stageTemplate = (props) => {
  const StageIcon = props.action === 'install' ? FaCheckCircle : FaRegCheckCircle;
  const iconSize = '16px';
  const status = props.stage;

  if (status === 'deployed') {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <StageIcon style={{ color: '#3B82F6', fontSize: iconSize, marginRight: '4px' }} />
        <span>Live</span>
      </div>
    );
  }
  if (status === 'order') {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FiMove style={{ color: 'gray', fontSize: iconSize, marginRight: '4px' }} />
        <span>Order</span>
      </div>
    );
  }
  return status;
};

const actionTemplate = (props) => {
  const iconSize = '16px';
  const status = props.action;

  if (status === 'test') {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <AiOutlineExperiment style={{ color: 'gray', fontSize: iconSize, marginRight: '4px' }} />
        <span>Test</span>
      </div>
    );
  }
  if (status === 'install') {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FaCheckCircle style={{ color: '#3B82F6', fontSize: iconSize, marginRight: '4px' }} />
        <span>Install</span>
      </div>
    );
  }
  if (status === 'update') {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <MdOutlineSystemUpdateAlt style={{ color: 'gray', fontSize: iconSize, marginRight: '4px' }} />
        <span>Update</span>
      </div>
    );
  }
  if (status === 'decom') {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <BsXCircleFill style={{ color: '#cc3300', fontSize: iconSize, marginRight: '4px' }} />
        <span>Decom</span>
      </div>
    );
  }
  return status;
};

export const ConnectGrid = [
  {
    type: 'checkbox',
    display: true,
    width: '40',
  },
  {
    field: 'connect_code',
    display: true,
    showInSummaryData: true,
    headerText: 'VPN Code',
    width: '120',
    textAlign: 'Left',
    template: codeTemplate,
  },
  {
    field: 'id',
    display: true,
    showInSummaryData: true,
    headerText: 'ID',
    width: '100',
    textAlign: 'Left',
  },
  {
    field: 'stage',
    display: false,
    headerText: 'Stage',
    width: '100',
    textAlign: 'Left',
    template: stageTemplate,
  },
  {
    field: 'action',
    display: false,
    headerText: 'Action',
    width: '100',
    textAlign: 'Left',
    template: actionTemplate,
  },
  {
    field: 'completed',
    display: false,
    headerText: 'Status',
    width: '100',
    textAlign: 'Left',
    template: statusTemplate,
  },
  {
    field: 'peer_site',
    display: true,
    showInSummaryData: true,
    headerText: 'B End',
    width: '100',
    textAlign: 'Left',
  },
  {
    field: 'user_code',
    display: false,
    headerText: 'User Code',
    width: '100',
    textAlign: 'Left',
  },
  {
    field: 'region',
    display: true,
    showInSummaryData: true,
    headerText: 'Region',
    width: '100',
    textAlign: 'Left',
  },
  {
    field: 'ip0',
    display: false,
    headerText: 'IP0',
    width: '100',
    textAlign: 'Left',
  },
  {
    field: 'ip1',
    display: false,
    headerText: 'IP1',
    width: '100',
    textAlign: 'Left',
  },
  {
    field: 'build_uri',
    display: true,
    showInSummaryData: true,
    headerText: 'Build',
    width: '100',
    textAlign: 'Left',
    template: buildUriTemplate,
  },
  {
    field: 'download_uri',
    display: true,
    showInSummaryData: true,
    headerText: 'Files',
    width: '100',
    textAlign: 'Left',
    template: downloadUriTemplate,
  },
  {
    field: 'oid_update',
    display: false,
    headerText: 'Update ID',
    width: '120',
    textAlign: 'Left',
    template: timeUpdatedTemplate,
  },
  {
    field: 'timestamp_updated',
    display: true,
    showInSummaryData: true,
    headerText: 'Updated',
    width: '120',
    textAlign: 'Left',
    template: timeUpdatedTemplate,
  },
  {
    field: 'timestamp',
    display: true,
    showInSummaryData: true,
    headerText: 'Created',
    width: '120',
    textAlign: 'Left',
    template: timeTemplate,
  },
  {
    field: 'contact_email',
    display: false,
    showInPropertiesData: true,
    headerText: 'Email',
    width: '100',
    textAlign: 'Left',
  },
  {
    field: 'ip0',
    display: false,
    showInPropertiesData: true,
    headerText: 'Remote IP (Primary)',
  },
  {
    field: 'ip1',
    display: false,
    showInPropertiesData: true,
    headerText: 'Remote IP (Secondary)',
  },
  {
    field: 'selectors_local',
    display: false,
    showInPropertiesData: true,
    headerText: 'Local Traffic Selectors',
  },
  {
    field: 'selectors_remote',
    display: false,
    showInPropertiesData: true,
    headerText: 'Remote Traffic Selectors',
  },
  {
    field: 'md5',
    display: false,
    showInPropertiesData: true,
    headerText: 'MD5 Key',
  },
  {
    field: 'asn',
    display: false,
    showInPropertiesData: true,
    headerText: 'ASN Number',
  },
];

