import React from 'react';

const Footer = () => (
  <div className="mt-2">
    <p className="dark:text-gray-200 text-gray-700 text-left m-4 text-xs">
      © 2024 All rights reserved by cloudtuple.com
    </p>
  </div>
);

export default Footer;
