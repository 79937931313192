import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { VscActivateBreakpoints } from 'react-icons/vsc';
import { MdOutlineCancel } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { PiCaretRightBold, PiCaretDownBold } from 'react-icons/pi';
import Collapse from '@mui/material/Collapse';
import { links } from '../data/general';
import { useStateContext } from '../contexts/ContextProvider';

const Sidebar = () => {
  const { currentColor, activeMenu, setActiveMenu, screenSize } = useStateContext();
  const [expandedSections, setExpandedSections] = useState(
    links.reduce((acc, link) => {
      acc[link.title] = true;
      return acc;
    }, {}),
  );

  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const toggleSection = (title) => {
    setExpandedSections((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
  };

  const activeLink = 'flex items-center gap-2 pl-4 pt-2 pb-2.5 text-white text-sm w-full';
  const normalLink = 'flex items-center gap-2 pl-4 pt-2 pb-2.5 text-gray-700 text-sm dark:text-gray-200 dark:hover:text-black hover:bg-light-gray w-full';

  return (
    <div className="w-48 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10 border-r border-gray-300">
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link to="/" onClick={handleCloseSideBar} className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900">
              <VscActivateBreakpoints /> <span>Cloudtuple</span>
            </Link>
            <TooltipComponent content="Menu" position="BottomCenter">
              <button
                type="button"
                onClick={() => setActiveMenu(!activeMenu)}
                style={{ color: currentColor }}
                className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
                aria-label="Settings"
              >
                <MdOutlineCancel />
              </button>
            </TooltipComponent>
          </div>
          <div className="mt-10">
            {links.map((item) => (
              <div key={item.title}>
                <button
                  type="button"
                  className="text-sm text-gray-500 dark:text-gray-100 mx-4 mb-3 mt-3 uppercase flex items-center w-full text-left"
                  onClick={() => toggleSection(item.title)}
                >
                  {expandedSections[item.title] ? (
                    <PiCaretDownBold style={{ marginRight: '10px' }} />
                  ) : (
                    <PiCaretRightBold style={{ marginRight: '10px' }} />
                  )}
                  <span style={{ fontSize: '13px' }}>{item.title}</span>
                </button>
                <Collapse
                  in={expandedSections[item.title]}
                  timeout="auto"
                >
                  {item.links.map((link) => (
                    <NavLink
                      to={`/${link.name}`}
                      key={link.name}
                      onClick={handleCloseSideBar}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? currentColor : '',
                      })}
                      className={({ isActive }) => (isActive ? activeLink : normalLink)}
                    >
                      <div className="flex items-center">
                        <div className="ml-8">
                          {link.icon}
                        </div>
                        <span className="capitalize ml-2">{link.display_name}</span>
                      </div>
                    </NavLink>
                  ))}
                </Collapse>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
