import React from 'react';
import { useParams } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import OrderPage from '../../components/Order';

const GATEWAY_HOST = process.env.REACT_APP_ENV === 'prod'
  ? 'https://gateway.imesh.cloudtuple.com'
  : 'http://127.0.0.1:8000';
const PROTO_URL = `${GATEWAY_HOST}/proto`;

export const InfraUpdate = () => {
  const { id } = useParams();
  const GATEWAY_URL = `${GATEWAY_HOST}/infra`;
  return (
    <OrderPage
      gatewayUrl={GATEWAY_URL}
      protoUrl={PROTO_URL}
      target="infra"
      action="update"
      icon={<EditIcon />}
      id={id}
    />
  );
};

export const ConnectUpdate = () => {
  const { id } = useParams();
  const GATEWAY_URL = `${GATEWAY_HOST}/connect`;
  return (
    <OrderPage
      gatewayUrl={GATEWAY_URL}
      protoUrl={PROTO_URL}
      target="connect"
      action="update"
      icon={<EditIcon />}
      id={id}
    />
  );
};

export const SecurityUpdate = () => {
  const { id } = useParams();
  const GATEWAY_URL = `${GATEWAY_HOST}/security`;
  return (
    <OrderPage
      gatewayUrl={GATEWAY_URL}
      protoUrl={PROTO_URL}
      target="security"
      action="update"
      icon={<EditIcon />}
      id={id}
    />
  );
};
