import React from 'react';

const Header = ({ title }) => (
  <div className="mb-6">
    <p className="text-sm text-gray-500">
      {title}
    </p>
  </div>
);

export default Header;
