import React from 'react';
import Logs from '../../components/TargetLogs';
import { InfraGrid } from '../../data/infraLogs';
import { ConnectGrid } from '../../data/connectLogs';
import { SecurityGrid } from '../../data/securityLogs';

const InfraLogs = () => <Logs target="infra" TargetGrid={InfraGrid} />;
const ConnectLogs = () => <Logs target="connect" TargetGrid={ConnectGrid} />;
const SecurityLogs = () => <Logs target="security" TargetGrid={SecurityGrid} />;

export { InfraLogs, ConnectLogs, SecurityLogs };
