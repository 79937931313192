import React from 'react';
import UpdateIcon from '@material-ui/icons/Update';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import HomeIcon from '@material-ui/icons/Home';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { FaCheckCircle, FaRegCheckCircle, FaFile, FaPlusSquare } from 'react-icons/fa';
import { BsXCircleFill } from 'react-icons/bs';
import { AiOutlineExperiment } from 'react-icons/ai';
import { LuShoppingCart } from 'react-icons/lu';
import { GoFile } from 'react-icons/go';
import { MdError, MdErrorOutline } from 'react-icons/md';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    marginTop: '0px',
    marginLeft: '0px',
    marginRight: '40px',
    marginBottom: '20px',
    // border: '1px solid lightgray',
    borderRadius: '10px',
    padding: '100px',
    maxWidth: '240px',
  },
  formControl: {
    minWidth: '200px',
    padding: '0px',
  },
  loadingText: {
    marginTop: '20px',
    marginLeft: '0px',
    marginRight: '40px',
    marginBottom: '20px',
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '8px',
      },
      '& input': {
        padding: '10px', // Adjust the padding to reduce the height
        fontSize: '14px',
      },
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 10px) scale(1)', // Adjust the label's position
      fontSize: '14px',
      backgroundColor: 'white',
      padding: '0 4px',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)', // Adjust the label's position when shrunk
    },
  },
  fieldContainer: {
    marginBottom: '20px', // Adjust this value for larger space between fields
  },
  configHeader: {
    fontWeight: 'bold',
    fontSize: '18px',
    margin: '0px 0 30px 0', // Adjust this value for more space below the heading
  },
  configContainer: {
    border: '0.8px solid lightgray',
    borderRadius: '10px',
    marginTop: '10px',
    marginLeft: '8px',
    boxShadow: '0 4px 4px rgba(0, 0, 0, 0.1)',
    minWidth: '300px',
  },
  productsContainer: {
    border: '0.8px solid lightgray',
    borderRadius: '10px',
    marginTop: '10px',
    marginLeft: '10px',
    boxShadow: '0 4px 4px rgba(0, 0, 0, 0.1)',
    minWidth: '300px',
  },
  innerContainer: {
    padding: '12px',
  },
  iconButton: {
    padding: '5px',
  },
  icon: {
    fontSize: '18px',
  },
  tooltip: {
    fontSize: '13px',
  },
});

export const getBgColor = (action) => {
  switch (action) {
    case 'install':
      return '#1A97F5'; // Blue
    case 'update':
      return '#E3963E'; // Butterscotch
    case 'decom':
      return '#cc3300'; // Wine Red
    case 'home':
      return '#021f35'; // Blue
    case 'logs':
      return '#E5E5E5'; // Gray
    case 'button':
      return '#FFFFFF'; // White
    default:
      return '#1A97F5'; // Default to blue
  }
};

export const getIcon = (action) => {
  switch (action) {
    case 'test':
      return <AiOutlineExperiment style={{ width: '18px', height: '18px', marginRight: '5px' }} />;
    case 'install':
      return <FaPlusSquare style={{ width: '18px', height: '18px', marginRight: '5px' }} />;
    case 'update':
      return <EditIcon style={{ width: '18px', height: '18px', marginRight: '5px' }} />;
    case 'decom':
      return <DeleteIcon style={{ width: '18px', height: '18px', marginRight: '5px' }} />;
    case 'home':
      return <HomeIcon style={{ width: '18px', height: '18px', marginRight: '5px' }} />;
    case 'logs':
      return <AssignmentIcon style={{ width: '18px', height: '18px', marginRight: '5px' }} />;
    default:
      return <UpdateIcon style={{ width: '18px', height: '18px', marginRight: '5px' }} />;
  }
};

export const getUiColor = (stage, action) => {
  switch (stage) {
    case 'deployed':
      switch (action) {
        case 'install':
          return '#1A97F5';
        case 'update':
          return '#E3963E';
        case 'decom':
          return '#cc3300';
        default:
          return '#7c7c7c';
      }
    case 'order':
      return '#7c7c7c';
    default:
      return '#7c7c7c';
  }
};

export const getUiBuildStatusIcon = (stage, action, buildStatus) => {
  const isFilledIcon = action === 'install' || action === 'update' || action === 'decom';

  switch (stage) {
    case 'deployed':
      switch (buildStatus) {
        case 'SUCCESS':
          return isFilledIcon ? FaCheckCircle : FaRegCheckCircle;
        default:
          return isFilledIcon ? MdError : MdErrorOutline;
      }
    case 'order':
      switch (buildStatus) {
        case 'SUCCESS':
          return isFilledIcon ? FaCheckCircle : FaRegCheckCircle;
        default:
          return isFilledIcon ? MdError : MdErrorOutline;
      }
    default:
      return FaCheckCircle;
  }
};

export const getUiStageIcon = (stage, action) => {
  switch (stage) {
    case 'deployed':
      switch (action) {
        case 'install':
        case 'update':
        case 'decom':
          return FaCheckCircle;
        default:
          return FaRegCheckCircle;
      }
    case 'order':
      return LuShoppingCart;
    default:
      return LuShoppingCart;
  }
};

export const getUiActionIcon = (stage, action) => {
  switch (action) {
    case 'install':
      return stage === 'deployed' ? FaCheckCircle : FaRegCheckCircle;
    case 'test':
      return AiOutlineExperiment;
    case 'update':
      return stage === 'deployed' ? FaCheckCircle : FaRegCheckCircle;
    case 'decom':
      return BsXCircleFill;
    default:
      return FaRegCheckCircle;
  }
};

export const getUiBuildIcon = (stage, action) => {
  switch (stage) {
    case 'deployed':
      switch (action) {
        case 'install':
        case 'update':
        case 'decom':
          return FaFile;
        default:
          return GoFile;
      }
    case 'order':
    default:
      return GoFile;
  }
};

export const getUiDownloadIcon = (stage, action) => {
  switch (stage) {
    case 'deployed':
      switch (action) {
        case 'install':
        case 'update':
        case 'decom':
          return FaFile;
        default:
          return GoFile;
      }
    case 'order':
    default:
      return GoFile;
  }
};
