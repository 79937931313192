import React from 'react';
import { CataloguePage } from '../../components/Catalogue';

const GATEWAY_HOST = process.env.REACT_APP_ENV === 'prod'
  ? 'https://gateway.imesh.cloudtuple.com'
  : 'http://127.0.0.1:8000';

export const Catalogue = () => {
  const PROTO_URL = `${GATEWAY_HOST}/proto`;
  return (
    <CataloguePage
      protoUrl={PROTO_URL}
    />
  );
};
